import React, {useCallback, useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';

import {Container} from "./styles";
import logo from "../../../assets/img/logo.png";
import {useDispatch, useSelector} from "react-redux";
import {actions} from "../../../redux/actions";
import AlertDelete from "../../Alert/AlertDelete";
import swal from "@sweetalert/with-react";
import {BsWhatsapp} from "react-icons/bs";
import {getSignature, testSignature} from "../../../services/Profile";
import {toast} from "react-toastify";
import messageTypes from "../../../constants/MessagesTypes";
import brokerTypes from "../../../constants/BrokerTypes";
import AnuncieAqui from "../../../assets/img/anuncie_aqui.png";
import {useTranslation} from "react-i18next";
import {FaHandshake} from "react-icons/fa";
import {GiReceiveMoney} from "react-icons/gi";
import {GoChecklist} from "react-icons/go";

export default function Sidebar() {
    const dispatch = useDispatch();
    const [deleteBank, setDeleteBank] = useState(false);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const { t } = useTranslation();

    const {menuOpen} = useSelector(
        (state) => state.MenuOpenReducer
    );

    const {id, broker, meta} = useSelector(
        (state) => state.BankReducer
    );

    const {isActiveSignature, isEnableFreeSignature, roles, isFreeSignature} = useSelector(
        (state) => state.UserReducer
    );

    const handleActiveSignatureFree = useCallback((redirectUrl) => {
        if (isEnableFreeSignature) {
            testSignature().then(() => {
                getSignature().then((data) => {
                    if (!data) {
                        return;
                    }
                    dispatch(actions.setActiveSignature(data.data.isSignatureActive));
                    dispatch(actions.setIsEnableFreeSignature(data.data.isEnableFreeSignature));
                    dispatch(actions.setIsFreeSignature(data.data.isFree));
                    dispatch(actions.setDueDateSignature(data.data.dueDate ? new Date(data.data.dueDate.date).toLocaleDateString() : null));
                });
            });
        }
    }, [isEnableFreeSignature, dispatch]);

    useEffect(handleActiveSignatureFree, [isEnableFreeSignature]);

    const handleRedirectUrl = useCallback((redirectUrl) => {
        if (isActiveSignature) {
            history.push(redirectUrl);
            return;
        }

        swal(
            {
                icon: "info",
                text: t('alert_signature'),
                buttons: [t('alert_signature_btn_close'), t('alert_signature_confirm')],
            }
        ).then((isConfirm) => {
            if (isConfirm) {
                history.push('/plans');
            }
        });

    }, [isActiveSignature, history]);

    async function handleTestSignature() {
        try {
            setLoading(true);
            await testSignature();
            window.location.href = '/dashboard';
        } catch (err) {
            setLoading(false);
            toast.error(messageTypes.ERROR_TEST_SIGNATURE);
        }
    }

    function getBroker(value) {
        for (var index in brokerTypes) {
            if (brokerTypes[index].key === value) {
                return brokerTypes[index].value
            }
        }

        return null
    }

    return (
        <Container
            className={menuOpen ? 'navbar-nav sidebar sidebar-dark accordion toggled' : 'navbar-nav sidebar sidebar-dark accordion'}
            id="accordionSidebar">

            {/* <!-- Sidebar - Brand --> */}
            <Link className="sidebar-brand d-flex align-items-center" to="/dashboard">
                <div className="sidebar-brand-icon">
                    <img width={menuOpen ? 80 : 140} src={logo}/>
                </div>
            </Link>

            {/* <!-- Divider --> */}
            <hr className="sidebar-divider my-0"/>

            {/* <!-- Nav Item - Dashboard --> */}
            {(id !== 0 && id !== null) && <>
                <li className="nav-item">
                    <Link className="nav-link" to="/dashboard">
                        <div className="nav-dashboard">
                            <i className="fas fa-fw fa-tachometer-alt"></i>
                            <span>Dashboard</span>
                        </div>
                    </Link>
                </li>
                <hr className="sidebar-divider"/>
                <li className="nav-item">
                    <Link className='nav-link' to="/bank/view">
                        <div className="nav-bank">
                            <i className="fas fa-wallet"></i>
                            <span>{t('navbar_bank')}</span>
                        </div>
                    </Link>
                </li>
                <li className="nav-item">
                    <div
                      style={{cursor: 'pointer'}}
                      className='nav-link'
                      onClick={() => {
                          handleRedirectUrl('/brokers');
                      }}
                    >
                        <div>
                            <i className="fa fa-list" aria-hidden="true"></i>{t('navbar_brokers')}
                        </div>
                    </div>
                </li>
                <li className="nav-item">
                    <div
                      style={{cursor: 'pointer'}}
                      className='nav-link'
                      onClick={() => {
                          handleRedirectUrl('/calc');
                      }}
                    >
                        <div>
                            <i className="fa fa-calculator" aria-hidden="true"></i>
                            <span>{t('navbar_calc')}
                                {!isActiveSignature && <>
                                    <small className="badge badge-success pl-2 pr-2 font-weight-bolder ml-2">PRO</small>
                                </>}
                            </span>
                        </div>
                    </div>
                </li>
                <li className="nav-item">
                    <div
                      className='nav-link'
                      onClick={() => {
                          handleRedirectUrl('/economic-calendar');
                      }}
                      style={{cursor: 'pointer'}}
                    >
                        <div className="nav-economic-calendar">
                            <i className="fa fa-calendar-day"></i>
                            <span>
                                {t('navbar_calendar')}
                                {!isActiveSignature && <>
                                    <small className="badge badge-success pl-2 pr-2 font-weight-bolder ml-2">PRO</small>
                                </>}
                            </span>
                        </div>
                    </div>
                </li>
                <li className="nav-item">
                    <Link className='nav-link' to="/bank/movements">
                        <div className="nav-movements">
                            <i className="fas fa-exchange-alt"></i>
                            <span>{t('navbar_moviments')}</span>
                        </div>
                    </Link>
                </li>
                <li className="nav-item">
                    <div
                      className='nav-link'
                      onClick={() => {
                          handleRedirectUrl('/estrategias');
                      }}
                      style={{cursor: 'pointer'}}
                    >
                        <div className="nav-strategy">
                            <i className="fa fa-list" aria-hidden="true"></i>
                            <span>
                                {t('navbar_strategies')}
                                {!isActiveSignature && <>
                                    <small className="badge badge-success pl-2 pr-2 font-weight-bolder ml-2">PRO</small>
                                </>}
                            </span>
                        </div>
                    </div>
                </li>
                <li className="nav-item">
                    <div
                      className='nav-link'
                      onClick={() => {
                          handleRedirectUrl(meta ? '/meta' : '/meta/save');
                      }}
                      style={{cursor: 'pointer'}}
                    >
                        <div className="nav-meta">
                            <i className="fa fa-check-square" aria-hidden="true"></i>
                            <span>
                                {t('navbar_meta')}
                                {!isActiveSignature && <>
                                    <small className="badge badge-success pl-2 pr-2 font-weight-bolder ml-2">PRO</small>
                                </>}
                            </span>
                        </div>
                    </div>
                </li>
                <li className="nav-item">
                    <div
                      className='nav-link'
                      onClick={() => {
                          handleRedirectUrl('/disciplina');
                      }}
                      style={{cursor: 'pointer'}}
                    >
                        <div className="nav-disciplina">
                            <i className="fa fa-graduation-cap" aria-hidden="true"></i>
                            <span>
                                {t('navbar_disciplina')}
                                {!isActiveSignature && <>
                                    <small className="badge badge-success pl-2 pr-2 font-weight-bolder ml-2">PRO</small>
                                </>}
                            </span>
                        </div>
                    </div>
                </li>

                <li className="nav-item">
                    <Link className='nav-link' to="/trading">
                        <div className="nav-trading">
                            <i className="fas fa-chart-bar"></i>
                            <span>{t('navbar_tradings')}</span>
                        </div>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className='nav-link' to="/currency-pair">
                        <div className="">
                            <i className="fas fa-list"></i>
                            <span>Pares de moedas</span>
                        </div>
                    </Link>
                </li>
                {roles && roles.includes('ROLE_ADMIN') ? <>
                    <li className="nav-item">
                        <a className="nav-link collapsed" href="#" data-toggle="collapse"
                           data-target="#collapseAffiliate"
                           aria-expanded="false" aria-controls="collapseConfig">
                            <i className="fa fa-users"></i>
                            <span>Programa de afiliados</span>
                        </a>
                        <div id="collapseAffiliate" className="collapse" aria-labelledby="collapseAffiliate"
                             data-parent="#accordionSidebar">
                            <div className="bg-white py-2 collapse-inner rounded">
                                <div className='collapse-item cursor-pointer' onClick={() => {
                                    handleRedirectUrl('/admin/affiliate-program/affiliates');
                                }}>
                                    <div className="">
                                        <FaHandshake size={18}/> Afiliados
                                    </div>
                                </div>
                                <div className='collapse-item cursor-pointer' onClick={() => {
                                    handleRedirectUrl('/admin/affiliate-program/commissions');
                                }}>
                                    <div className="r">
                                        <GiReceiveMoney size={18}/> <span>Comissões</span>
                                    </div>
                                </div>
                                <div className='collapse-item cursor-pointer' onClick={() => {
                                    handleRedirectUrl('/admin/affiliate-program/commission/payment/list');
                                }}>
                                    <div className="r">
                                        <GoChecklist size={18}/> <span>Pagamentos</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </> : <>
                    <li className="nav-item">
                        <Link className='nav-link' to="/affiliate-program">
                            <div className="">
                                <i className="fa fa-users"></i>
                                <span>Programa de afiliados</span>
                            </div>
                        </Link>
                    </li>
                </>}
                {(!isActiveSignature || isFreeSignature === true) && <>
                    <li className="nav-item">
                        <Link className='nav-link' to="/plans">
                            <div>
                                <i className="fa fa-list-ul" aria-hidden="true"></i>
                                <span>{t('navbar_plans')}</span>
                            </div>
                        </Link>
                    </li>
                </>}
                <li className="nav-item">
                    <a className="nav-link collapsed nav-report" href="#" data-toggle="collapse"
                       data-target="#collapseReport"
                       aria-expanded="false" aria-controls="collapseConfig">
                        <i className="far fa-file-alt"></i>
                        <span>{t('navbar_report')}</span>
                    </a>
                    <div id="collapseReport" className="collapse" aria-labelledby="collapseReport"
                         data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <div className='collapse-item cursor-pointer' onClick={() => {
                                handleRedirectUrl('/disciplina/report');
                            }}>
                                <div className="nav-report">
                                    <i className="fa fa-graduation-cap"
                                       aria-hidden="true"></i> {t('navbar_report_disciplina')}
                                    {!isActiveSignature && <>
                                        <small
                                          className="badge badge-success pl-2 pr-2 font-weight-bolder ml-2">PRO</small>
                                    </>}
                                </div>
                            </div>
                            <div className='collapse-item cursor-pointer' onClick={() => {
                                handleRedirectUrl('/trading/report-performance');
                            }}>
                                <div className="nav-report">
                                    <i className="fas fa-chart-line"></i> {t('navbar_report_performance')}
                                    {!isActiveSignature && <>
                                        <small
                                          className="badge badge-success pl-2 pr-2 font-weight-bolder ml-2">PRO</small>
                                    </>}
                                </div>
                            </div>
                            <div className='collapse-item cursor-pointer' onClick={() => {
                                handleRedirectUrl('/placar');
                            }}>
                                <div className="nav-report">
                                    <i className="fa fa-list-ul"></i> {t('navbar_report_history')}
                                    {!isActiveSignature && <>
                                        <br/>
                                        <small
                                          className="badge badge-success pl-2 pr-2 font-weight-bolder ml-2">PRO</small>
                                    </>}
                                </div>
                            </div>
                            <div className='collapse-item cursor-pointer' onClick={() => {
                                handleRedirectUrl('/trading/report');
                            }}>
                                <div className="nav-report">
                                    <i className="fas fa-chart-bar"></i> {t('navbar_report_tradings')}
                                    {!isActiveSignature && <>
                                        <small
                                          className="badge badge-success pl-2 pr-2 font-weight-bolder ml-2">PRO</small>
                                    </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseConfig"
                       aria-expanded="false" aria-controls="collapseConfig">
                        <i className="fa fa-cogs"/>
                        <span>{t('navbar_config')}</span>
                    </a>
                    <div id="collapseConfig" className="collapse" aria-labelledby="collapseConfig"
                         data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                          <span
                            className="collapse-item"
                            onClick={() => {
                                setDeleteBank(true)
                            }}
                            style={{cursor: 'pointer'}}
                          >
                              <i className="fa fa-trash" aria-hidden="true"/> {t('navbar_remove_bank')}
                              {deleteBank && <AlertDelete
                                functionCallbackCancel={() => {
                                    setDeleteBank(false);
                                }}
                                functionCallbackConfirm={() => {
                                    window.location = "/";
                                }}
                                text={t('alert_remove_bank', {
                                    'broker': getBroker(broker)
                                })}
                                urlDelete={process.env.REACT_APP_API_ENDPOINT + `/api/bank/delete`}
                              />}
                          </span>
                        </div>
                    </div>
                </li>
                <hr className="sidebar-divider d-none d-md-block"/>
                {!isActiveSignature && !loading && isEnableFreeSignature && <>
                    {!menuOpen ?
                      <>
                          <div className="d-none d-md-inline text-center mt-4 ml-3 mr-3 rounded"
                               style={{color: 'rgba(255, 255, 255, 0.8)', backgroundColor: 'rgba(0,0,0,.1)'}}>
                              <div className={"pl-2 pr-2 pt-3 pb-3"}>
                                  <p className="text-center small">
                                      {t('navbar_test_free')}
                                  </p>
                                  <div className="btn btn-success btn-sm font-weight-bold"
                                       onClick={handleTestSignature}>
                                      {t('navbar_btn_test')}
                                  </div>
                              </div>
                          </div>
                      </> :
                      <>
                          <div className="d-none d-md-inline text-center mt-4 ml-3 mr-3  mb-5"
                               style={{color: 'rgba(255, 255, 255, 0.8)'}}>
                              <div className={"pl-2 pr-2 pt-3"}>
                                  <div className="btn btn-success btn-sm font-weight-bold"
                                       onClick={handleTestSignature}>
                                      {t('navbar_btn_test')}
                                  </div>
                              </div>
                          </div>
                      </>
                    }
                </>}
            </>}
            {!menuOpen && <>
                <div className="small mt-5 text-center mb-4" style={{color: 'rgba(255, 255, 255, 0.8)'}}>
                    <span>{t('support')}<br/>
                        <a className="text-white pl-1" href="https://web.whatsapp.com/send?phone=38999361310"
                           target="_blank">
                            <BsWhatsapp color={'#25D366'}/> <u>(38) 99936-1310</u>
                        </a>
                    </span>
                </div>
                <div className="small mt-4 text-center mb-4 border-secondary d-none">
                    <img
                      style={{border: '1px solid #dddfeb'}}
                      width={190} height={190} src={AnuncieAqui} alt={"Anuncie aqui"}
                    />
                </div>
            </>}
            <div className="text-center d-none d-md-inline">
                <button onClick={() => {
                    dispatch(actions.clickMenuOpen());
                }} className="rounded-circle border-0" id="sidebarToggle"></button>
            </div>
        </Container>
    )
}