import api from "../api";
import {toast} from "react-toastify";
import messageTypes from "../../constants/MessagesTypes";

export async function getCurrencyPairs(data) {
  return api.get(`/api/currency-pair/list`, {
    params: data,
    headers: {
      'Content-Type': 'multipart/form-data',
      auth: true
    },
  }).catch((error) => {
    toast.error(messageTypes.ERROR_LOAD);
    return[];
  });
}

export async function getCurrencyPair($id) {
  try {

    const response = await api.get(`/api/currency-pair/${$id}/find`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        auth: true
      },
    });

    return response.data.data ? response.data.data : {};

  } catch (err) {
    toast.error(messageTypes.ERROR_LOAD);
    throw err;
  }
}

export function edit($id, $currencyPair) {
  return api.post(`/api/currency-pair/edit/${$id}`, $currencyPair, {
    headers: {
      'Content-Type': 'application/json',
      auth: true
    },
  });
}

export function save($currencyPair) {
  return api.post('/api/currency-pair/new', $currencyPair, {
    headers: {
      'Content-Type': 'application/json',
      auth: true
    },
  });
}