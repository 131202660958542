import actionsTypes from "../constants/ActionTypes";

export const actions = {
    clickMenuOpen: () => ({
        type: actionsTypes.CLICK_OPEN_MENU,
    }),
    updateBank: (bank) => ({
        type: actionsTypes.UPDATE_BANK,
        bank: bank,
    }),
    setBanks: (banks) => ({
        type: actionsTypes.SET_BANKS,
        banks: banks,
    }),
    setHideAmount: (hideAmount) => ({
        type: actionsTypes.HIDE_AMOUNT,
        hideAmount,
    }),
    setShowTour: (showTour) => ({
        type: actionsTypes.SHOW_TOUR,
        showTour,
    }),
    setShowModalInfo: (showModalInfo) => ({
        type: actionsTypes.SHOW_MODAL_INFO,
        showModalInfo,
    }),
    setShowTourImport: (showTourImport) => ({
        type: actionsTypes.SHOW_TOUR_IMPORT,
        showTourImport,
    }),
    setShowTourTrading: (showTourTrading) => ({
        type: actionsTypes.SHOW_TOUR_TRADING,
        showTourTrading,
    }),
    setActiveSignature: (isActiveSignature) => ({
        type: actionsTypes.SET_ACTIVE_SIGNATURE,
        isActiveSignature,
    }),
    setIsFreeSignature: (isFreeSignature) => ({
        type: actionsTypes.SET_IS_FREE_SIGNATURE,
        isFreeSignature,
    }),
    setDueDateSignature: (dueDate) => ({
        type: actionsTypes.SET_DUE_DATE_SIGNATURE,
        dueDate,
    }),
    setIsEnableFreeSignature: (isEnableFreeSignature) => ({
        type: actionsTypes.SET_IS_ENABLE_FREE_SIGNATURE,
        isEnableFreeSignature,
    }),
    setAffiliateID: (affiliateID) => ({
        type: actionsTypes.SET_AFFILIATE_ID,
        affiliateID,
    }),
    setId: (id) => ({
        type: actionsTypes.SET_ID,
        id,
    }),
    setKeyPix: (keyPix) => ({
        type: actionsTypes.SET_KEY_PIX,
        keyPix,
    }),
    setRoles: (roles) => ({
        type: actionsTypes.SET_ROLES,
        roles,
    }),
};

export const clickMenuOpen = value => ({
    type: actionsTypes.CLICK_OPEN_MENU,
    newValue: value
});

