import React, {useEffect, useState} from 'react';
import CustomButton from "../../../../components/Form/Button";
import {getCommissionsPayments} from "../../../../services/Profile";
import {Alert} from "@material-ui/core";
import messageTypes from "../../../../constants/MessagesTypes";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import {useTranslation} from "react-i18next";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";
import {currencyFormat} from "../../../../utils/masks";
import {GoChecklist} from "react-icons/go";

export default function PaymentCommission() {
  const [loadingList, setLoadingList] = useState(false);
  const { t } = useTranslation();
  const [payments, setPayments] = useState([]);
  const [page, setPage] = useState(1);

  useEffect( () => {
    setLoadingList(true);
    getCommissionsPayments({
      page: page,
      per_page: 25,
    }).then((data) => {
      if (!data || data === '') {
        return;
      }

      setPayments(data.data);
    }).finally(() => {
      setLoadingList(false);
    });

    return function cleanup() {
      setPayments([]);
    };
  }, [page]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    return (
      <>
        <div className="row d-flex justify-content-center">
          <div className="col-md-9 col-sm-12">
            <div className="card shadow h-100 p-3">
              <div className="card-header py-3">
                <div className="row">
                  <div className="col d-flex flex-column justify-content-center">
                            <span className="m-0 font-weight-bold">
                                <GoChecklist size={18}/> Pagamentos
                            </span>
                  </div>
                </div>
              </div>
              {loadingList ?
                <div className="row pl-4 pr-4 pb-4 pt-0">
                  <div className="col">
                    <CustomButton
                      className="btn btn-primary col-12 text-capitalize font-weight-bold"
                      loading={loadingList}
                      type="button"
                    >
                      {t('loading')}
                    </CustomButton>
                  </div>
                </div> :
                <>
                  {payments && payments.data && payments.data.length ?
                    <TableContainer component={Paper}>
                      <Table aria-label="caption table">
                        <TableHead>
                          <TableRow>
                            <TableCell className="font-weight-bold text-muted">Data</TableCell>
                            <TableCell className="font-weight-bold text-muted">Afiliado</TableCell>
                            <TableCell className="font-weight-bold text-muted">Valor</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {payments.data.map((payment) => (
                            <TableRow key={payment.id}>
                              <TableCell>
                                {new Date(payment.created.date).toLocaleDateString()}
                              </TableCell>
                              <TableCell>
                                {payment.affiliateUser ? payment.affiliateUser.email : ''}
                                <br/>
                                <small>
                                  ID: {payment.affiliateUser ? (payment.affiliateUser.id).split('-')[0] : ''}
                                  <br/>
                                  ID
                                  afiliação: {payment.affiliateUser ? (payment.affiliateUser.affiliateID).split('-')[0] : ''}
                                </small>
                              </TableCell>
                              <TableCell>
                                {currencyFormat(parseFloat(payment.value ? payment.value : 0), 'R$')}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter>
                          <TableCell colSpan="5">
                            <span className="text-muted text-info float-left pl-4 pt-2">
                                {t('table_pagination_text', {
                                  'page': page,
                                  'totalPage': payments.total_page + 1
                                })}
                            </span>
                            <button
                              style={{cursor: !payments.next_page ? 'not-allowed' : 'pointer'}}
                              type="button"
                              onClick={() => setPage(page + 1)}
                              disabled={!payments.next_page}
                              className="btn btn-light mr-3 float-right">
                              {t('table_pagination_next')} <IoIosArrowForward
                            />
                            </button>
                            <button
                              style={{cursor: page <= 1 ? 'not-allowed' : 'pointer'}}
                              type="button"
                              onClick={() => setPage(page - 1)}
                              disabled={page <= 1}
                              className="btn btn-light mr-3 float-right"
                            >
                              <IoIosArrowBack/> {t('table_pagination_back')}
                            </button>
                          </TableCell>
                        </TableFooter>
                      </Table>
                    </TableContainer> :
                    <div className="row pb-4 pt-0">
                      <div className="col">
                        <Alert severity="warning">{messageTypes.REGISTER_NOT_FOUNT}</Alert>
                      </div>
                    </div>
                  }
                </>
              }
              <br/>
            </div>
          </div>
        </div>
      </>
    )
}