import actionsTypes from "../constants/ActionTypes";

const initialState = {
    showTour: false,
    showTourTrading: false,
    showTourImport: false,
    showModalInfo: false,
    isActiveSignature: null,
    isFreeSignature: null,
    isEnableFreeSignature: false,
    dueDate: null,
    id: null,
    affiliateID: null,
    keyPix: null,
    roles: [],
}

export const UserReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionsTypes.SHOW_TOUR:
            return {
                ...state,
                showTour: action.showTour
            };
        case actionsTypes.SHOW_MODAL_INFO:
            return {
                ...state,
                showModalInfo: action.showModalInfo
            };
        case actionsTypes.SHOW_TOUR_IMPORT:
            return {
                ...state,
                showTourImport: action.showTourImport
            };
        case actionsTypes.SHOW_TOUR_TRADING:
            return {
                ...state,
                showTourTrading: action.showTourTrading
            };
        case actionsTypes.SET_ACTIVE_SIGNATURE:
            return {
                ...state,
                isActiveSignature: action.isActiveSignature
            };
        case actionsTypes.SET_IS_ENABLE_FREE_SIGNATURE:
            return {
                ...state,
                isEnableFreeSignature: action.isEnableFreeSignature
            };
        case actionsTypes.SET_IS_FREE_SIGNATURE:
            return {
                ...state,
                isFreeSignature: action.isFreeSignature
            };
        case actionsTypes.SET_DUE_DATE_SIGNATURE:
            return {
                ...state,
                dueDate: action.dueDate
            };
        case actionsTypes.SET_AFFILIATE_ID:
            return {
                ...state,
                affiliateID: action.affiliateID
            };
        case actionsTypes.SET_ID:
            return {
                ...state,
                id: action.id
            };
        case actionsTypes.SET_KEY_PIX:
            return {
                ...state,
                keyPix: action.keyPix
            };
        case actionsTypes.SET_ROLES:
            return {
                ...state,
                roles: action.roles
            };
        default:
            return state;
    }
};