import React, {useEffect, useRef, useState} from 'react';
import CustomButton from "../../../../components/Form/Button";
import {getAffiliates} from "../../../../services/Profile";
import {toast} from "react-toastify";
import {Alert} from "@material-ui/core";
import {FaHandshake} from "react-icons/fa";
import * as Yup from "yup";
import messageTypes from "../../../../constants/MessagesTypes";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import {useTranslation} from "react-i18next";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";
import {Form} from "@unform/web";
import {AiOutlineSearch} from "react-icons/ai";
import Input from "../../../../components/Form/Input";

export default function Affiliate() {
  const [loadingList, setLoadingList] = useState(false);
  const { t } = useTranslation();
  const [usersAffiliates, setUsersAffiliates] = useState([]);
  const [page, setPage] = useState(1);
  const formRef = useRef(null);
  const [filter, setFilter] = useState({
    stringSearch: '',
  });

  useEffect( () => {
    setLoadingList(true);
    getAffiliates({
      page: page,
      per_page: 25,
      stringSearch: filter.stringSearch,
    }).then((data) => {
      if (!data || data === '') {
        return;
      }

      setUsersAffiliates(data.data);
    }).finally(() => {
      setLoadingList(false);
    });

    return function cleanup() {
      setUsersAffiliates([]);
    };
  }, [page, filter]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function handleSubmit(data) {
    try {

      formRef.current.setErrors({});
      const schema = Yup.object().shape({});

      await schema.validate(data, {
        abortEarly: false,
      });

      setFilter({
        stringSearch: data.stringSearch,
      });

    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors = {};
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        toast.warning(messageTypes.REQUIRED_FIELDS);
        return;
      }

      toast.error(messageTypes.ERROR);
    }
  }

    return (
      <>
        <div className="row d-flex justify-content-center">
          <div className="col-md-8 col-sm-12">
            <div className="card shadow h-100 p-3">
              <div className="card-header py-3">
                <div className="row">
                  <div className="col d-flex flex-column justify-content-center">
                    <span className="m-0 font-weight-bold">
                        <FaHandshake size={18}/> Afiliados
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="">
                    <div className="row">
                      <div className="col p-3">
                        <Form ref={formRef} onSubmit={handleSubmit} className="pr-4 pl-4">
                          <div className="row">
                            <div className={"d-flex col-12"}>
                              <div className="mt-3 w-100">
                                <Input
                                  type="text"
                                  name="stringSearch"
                                  placeholder={"ID, nome ou e-mail"}
                                  label={'Pesquisar usuário'}
                                />
                              </div>
                              <CustomButton
                                className="btn btn-primary font-weight-bold text-capitalize mt-3 ml-3"
                                type="submit"
                                startIcon={<AiOutlineSearch/>}
                              >
                                {t('filter_btn_submmit')}
                              </CustomButton>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr/>
              {loadingList ?
                <div className="row pl-4 pr-4 pb-4 pt-0">
                  <div className="col">
                    <CustomButton
                      className="btn btn-primary col-12 text-capitalize font-weight-bold"
                      loading={loadingList}
                      type="button"
                    >
                      {t('loading')}
                    </CustomButton>
                  </div>
                </div> :
                <>
                  {usersAffiliates && usersAffiliates.data && usersAffiliates.data.length ?
                    <TableContainer component={Paper}>
                      <Table aria-label="caption table">
                        <TableHead>
                          <TableRow>
                            <TableCell className="font-weight-bold text-muted">ID do usuário</TableCell>
                            <TableCell className="font-weight-bold text-muted">Email</TableCell>
                            <TableCell className="font-weight-bold text-muted">ID de afiliação</TableCell>
                            <TableCell className="font-weight-bold text-muted">Qtd de indicados</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {usersAffiliates.data.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell>
                                {(row.id).split('-')[0]}
                                <br/>
                                <small>Criado em: {new Date(row.created.date).toLocaleDateString()}</small>
                              </TableCell>
                              <TableCell>
                                {row.name}
                                <br/>
                                <small>{row.email}</small>
                              </TableCell>
                              <TableCell>
                                {row.affiliateID}
                              </TableCell>
                              <TableCell>
                                {row.qtdReferredUsers}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter>
                          <TableCell colSpan="5">
                                <span className="text-muted text-info float-left pl-4 pt-2">
                                    {t('table_pagination_text', {
                                      'page': page,
                                      'totalPage': usersAffiliates.total_page + 1
                                    })}
                                </span>
                            <button
                              style={{cursor: !usersAffiliates.next_page ? 'not-allowed' : 'pointer'}}
                              type="button"
                              onClick={() => setPage(page + 1)}
                              disabled={!usersAffiliates.next_page}
                              className="btn btn-light mr-3 float-right">
                              {t('table_pagination_next')} <IoIosArrowForward
                            />
                            </button>
                            <button
                              style={{cursor: page <= 1 ? 'not-allowed' : 'pointer'}}
                              type="button"
                              onClick={() => setPage(page - 1)}
                              disabled={page <= 1}
                              className="btn btn-light mr-3 float-right"
                            >
                              <IoIosArrowBack/> {t('table_pagination_back')}
                            </button>
                          </TableCell>
                        </TableFooter>
                      </Table>
                    </TableContainer> :
                    <div className="row pb-4 pt-0">
                      <div className="col">
                        <Alert severity="warning">{messageTypes.REGISTER_NOT_FOUNT}</Alert>
                      </div>
                    </div>
                  }
                </>
              }
              <br/>
            </div>
          </div>
        </div>
      </>
    )
}