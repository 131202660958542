import * as Yup from "yup";
import {toast} from "react-toastify";
import React, {useEffect, useRef, useState} from "react";
import {Form} from "@unform/web";
import {Link, useHistory} from "react-router-dom";
import {BiArrowBack} from "react-icons/bi";
import {IoMdSave} from "react-icons/io";
import {Button, InputAdornment} from "@material-ui/core";
import InputMask from "../../../components/Form/InputMask";
import InputSelect from "../../../components/Form/InputSelect";
import CustomButton from "../../../components/Form/Button";
import messageTypes from "../../../constants/MessagesTypes";
import currencyPairTypes from "../../../constants/CurrencyPairTypes";
import {edit, find, save} from "../../../services/Trading";
import {useDispatch, useSelector} from "react-redux";
import {getBank} from "../../../services/Bank";
import {actions} from "../../../redux/actions";
import InputDate from "../../../components/Form/InputDate";
import {currencyFormat} from "../../../utils/masks";
import {getStrategies} from "../../../services/Strategy";
import swal from "@sweetalert/with-react";
import {useTranslation} from "react-i18next";
import Input from "../../../components/Form/Input";
import i18n from "../../../services/i18n";
import {getCurrencyPairs} from "../../../services/CurrencyPair";
import {IoAddCircle} from "react-icons/io5";

export default function NewTrading({match}) {
    const [loading, setLoading] = useState(false);
    const [trading, setTrading] = useState(0);
    const [strategies, setStrategies] = useState(null);
    const formRef = useRef(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const { coinType } = useSelector((state) => state.BankReducer);
    const { t } = useTranslation();
    const [image, setImage] = useState(null);
    const [currencyPairs, setCurrencyPairs] = useState([]);

    const directionTrading = [
        {key: 'COMPRA', value: t('trading_label_input_direction_select_option_1')},
        {key: 'VENDA', value: t('trading_label_input_direction_select_option_2')},
    ];

    useEffect( () => {
        getCurrencyPairs().then((response) => {
            if (!response || response === '') {
                setCurrencyPairs([...currencyPairTypes]);
                return;
            }

            var newListCurrencyPair = [...currencyPairTypes];
            response.data.data.forEach((pair) => {
                newListCurrencyPair.push({key: pair.name, value: pair.name})
            });

            setCurrencyPairs(newListCurrencyPair);
            console.log(newListCurrencyPair);
        });

        return function cleanup() {
            setCurrencyPairs([]);
        };
    }, [currencyPairTypes]);


    async function handleSaveNewTrading(data) {
        try {

            if (loading) {
                return;
            }

            setDataTrading(data);

            setLoading(true);
            formRef.current.setErrors({});
            const schema = Yup.object().shape({
                investmentValue: Yup.string().required(t('input_required')),
                profitObtained: Yup.string().required(t('input_required')),
                currencyPair: Yup.string().required(t('input_required')),
                created: Yup.string().required(t('input_required')),
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            const formData = new FormData();
            if (data.observation) {
                formData.append('observation', data.observation);
            }

            if (data.direction) {
                formData.append('direction', data.direction);
            }
            formData.append('investmentValue', data.investmentValue.replace(/[^0-9,-]*/g, '').replace(',', '.'));
            formData.append('profitObtained', data.profitObtained.replace(/[^0-9,-]*/g, '').replace(',', '.'));
            formData.append('currencyPair', data.currencyPair);
            formData.append('strategy', data.strategy);
            formData.append('created', data.created);

            if (image && image !== '') {
                formData.append('image', image);
            }

            const response = (trading) ? await edit(trading.id, formData) : await save(formData);

            if (response && response.status === 200) {
                toast.success(messageTypes.SUCCESS);
                getBank().then((data) => {
                    dispatch(actions.updateBank(data));

                    const meta = data.meta;
                    if (!meta) {
                        return;
                    }

                    const profitObtainedToday = parseFloat(data.profitObtainedToday);
                    if (profitObtainedToday >= parseFloat(meta.stopWin)) {
                        swal(
                            {
                                icon: "success",
                                title: t('trading_goal_stop_win'),
                            }
                        );
                    } else if (profitObtainedToday <= parseFloat(meta.stopLoss)) {
                        swal(
                            {
                                icon: "warning",
                                title: t('trading_goal_stop_loss'),
                            }
                        );
                    }
                })
                history.push('/trading');
            } else {
                toast.error(messageTypes.ERROR_SAVE);
            }

            setLoading(false);

        } catch (err) {
            setLoading(false);
            if (err instanceof Yup.ValidationError) {
                const validationErrors = {};
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
                toast.warning(messageTypes.REQUIRED_FIELDS);
                return;
            }

            toast.error(messageTypes.ERROR);
        }
    }

    function setDataTrading(data) {
        if (trading) {
            trading.currencyPair = data.currencyPair;
            trading.investmentValue = data.investmentValue;
            trading.profitObtained = data.profitObtained;
            setTrading(trading);
        }
    }

    useEffect(() => {
        const id = match.params.id;
        if (id) {
            setLoading(true);
            find(id).then((data) => {
                setTrading(data);
            }).finally(() => setLoading(false));
        }

        getStrategies().then((data) => {
            if (data && data.data && data.data.data && data.data.data.length) {
                const list = data.data.data;
                let arrayList = [];
                for (var index in list) {
                    const strategy = list[index];
                    arrayList.push({
                        key: strategy.id,
                        value: strategy.name
                    });
                }
                setStrategies(arrayList);
            } else {
                setStrategies([]);
            }
        });
    }, []);

    function alertImageSize() {
        swal({
            text: t('fundamentalist_limit_img'),
        });
    }

    function showImage(urlImage, currencyPair) {
        swal(
            {
                showCloseButton: true,
                className: 'trading-modal',
                confirmButtonText: 'Fechar',
                content: <div>
                    <h3 className={"font-weight-bolder"}>{currencyPair}</h3>
                    <br/>
                    <div className={"img-trading-modal"}>
                        <img src={urlImage} width={'80%'} height={"80%"} alt={'Imagem da negociação'}/>
                    </div>
                </div>,
            }
        );
    }

    return (
        <>
            <div className="row justify-content-center">
                <div className="col-lg-8 col-xs-12 col-xl-6">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold">
                                {trading ?
                                    <>
                                        <i className="fas fa-edit"/> {t('traging_card_add_title')}
                                    </> :
                                    <>
                                        <i className="fas fa-plus"/> {t('traging_card_edit_title')}
                                    </>
                                }
                            </h6>
                        </div>
                        <div className="card-body pt-4">
                            {
                                !loading && (
                                    <Form ref={formRef} onSubmit={handleSaveNewTrading}>
                                        <div className="row mb-4">
                                            <div className="col-6">
                                                <InputDate
                                                    name="created"
                                                    label={t('trading_label_input_date') + ' *'}
                                                    value={(trading) ? new Date(trading.created.date) : new Date()}
                                                />
                                            </div>
                                            <div className="col-6 d-flex">
                                                <InputSelect
                                                    type="text"
                                                    name="currencyPair"
                                                    label={t('trading_label_input_currency_pair') + ' *'}
                                                    options={currencyPairs}
                                                    defaultValue={trading ? trading.currencyPair : ''}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment
                                                          position="start">{coinType}</InputAdornment>,
                                                    }}
                                                />
                                                <Button
                                                  onClick={() => {
                                                      history.push('/currency-pair/new')
                                                  }}
                                                  size={'small'}
                                                >
                                                    <IoAddCircle size={40}/>
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col-6">
                                                <InputMask
                                                    type="text"
                                                    name="investmentValue"
                                                    label={t('trading_label_input_value_invested') + ' *'}
                                                    mask={'currency'}
                                                    defaultValue={(trading) ? currencyFormat(trading.investmentValue) : null}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment
                                                            position="start">{coinType}</InputAdornment>,
                                                    }}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <InputMask
                                                    className="inputProfitObtained"
                                                    type="text"
                                                    name="profitObtained"
                                                    label={t('trading_label_input_profit_prejudice') + ' *'}
                                                    mask={'currency'}
                                                    defaultValue={(trading) ? currencyFormat(trading.profitObtained) : null}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment
                                                            position="start">{coinType}</InputAdornment>,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col-6">
                                                <InputSelect
                                                    type="text"
                                                    name="direction"
                                                    label={t('trading_label_input_direction')}
                                                    options={directionTrading}
                                                    defaultValue={trading && trading.direction ? trading.direction : ''}
                                                />
                                            </div>
                                            <div className="col-6">
                                                {strategies && <InputSelect
                                                    type="text"
                                                    name="strategy"
                                                    label={t('trading_label_input_strategy')}
                                                    options={strategies}
                                                    defaultValue={trading && trading.strategy ? trading.strategy.id : ''}
                                                />}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <Input
                                                    type="text"
                                                    name="observation"
                                                    defaultValue={trading.observation}
                                                    label={t('trading_label_observation')}
                                                    placeholder={''}
                                                    multiline
                                                    rows={4}
                                                />
                                            </div>
                                        </div>
                                        <div className="row pt-4 pb-5">
                                            <div className="col-12 d-flex align-items-center">
                                                {trading && trading.image && trading.image !== '' ?
                                                    <div className="img-trading cursor-pointer mr-2 mt-1">
                                                        <img
                                                            onClick={() => {
                                                                showImage(`${process.env.REACT_APP_API_ENDPOINT}/${trading.image}`, trading.currencyPair);
                                                            }}
                                                            width={'100%'}
                                                            height={"100%"}
                                                            src={`${process.env.REACT_APP_API_ENDPOINT}/${trading.image}`}
                                                            alt={"Imagem da negociação"}
                                                        />
                                                    </div> : <></>
                                                }
                                                <div>
                                                    <span className={"text-muted"}>{trading && trading.image && trading.image !== '' ? t('trading_edit_image'): t('trading_image')}</span>
                                                    <div className={"cursor-pointer mt-1"}>
                                                        <input className={"text-muted"} type="file" accept="image/*"
                                                               onChange={(event) => {
                                                                   const file = event.target.files[0];
                                                                   const maxSizeInBytes = 2 * 1024 * 1024;
                                                                   if (file && file.size > maxSizeInBytes) {
                                                                       alertImageSize();
                                                                       event.target.value = null;
                                                                       return;
                                                                   }
                                                                   setImage(event.target.files[0]);
                                                               }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div className="row">
                                            <div className="col-12">
                                                <Link to="/trading">
                                                    <CustomButton
                                                        variant="outlined"
                                                        className="btn btn-secondary col-4 float-left font-weight-bold text-capitalize"
                                                        startIcon={<BiArrowBack/>}
                                                    >
                                                        {t('btn_back')}
                                                    </CustomButton>
                                                </Link>
                                                <CustomButton
                                                    className="btn btn-primary col-4 float-right font-weight-bold text-capitalize"
                                                    loading={loading}
                                                    type="submit"
                                                    startIcon={<IoMdSave/>}
                                                >
                                                    {t('btn_save')}
                                                </CustomButton>
                                            </div>
                                        </div>
                                    </Form>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}