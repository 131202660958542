import api from '../api';
import {toast} from "react-toastify";
import messageTypes from "../../constants/MessagesTypes";

export function save(data) {
  return api.post('/api/admin/affiliate-program/commission/payment/new', data, {
    headers: {
      'Content-Type': 'application/json',
      auth: true
    },
  });
}
