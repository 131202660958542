import React, {useEffect} from "react";
import swal from "@sweetalert/with-react";
import api from "../../../services/api";
import {toast} from "react-toastify";
import messageTypes from "../../../constants/MessagesTypes";
import i18n from "../../../services/i18n";

function AlertDelete({
   title = i18n.t('alert_confirm_title'),
   text = i18n.t('alert_delete_text'),
   urlCallback,
   functionCallbackCancel = null,
   functionCallbackConfirm = null,
   urlDelete,
   setSuccessDelete = () => {}
}) {

    useEffect(() => {
        swal(
            {
                title,
                text,
                icon: "warning",
                dangerMode: true,
                buttons: [i18n.t('alert_cancel'), i18n.t('alert_confirm')],
            }
        ).then((isConfirm) => {
            if (isConfirm) {
                api.delete(urlDelete, {
                    headers: {
                        'Content-Type': 'application/json',
                        auth: true
                    },
                }).then((response => {
                    toast.success(messageTypes.SUCCESS);
                    if (urlCallback) {
                        window.location.href = urlCallback
                    } else if (setSuccessDelete) {
                        setSuccessDelete(true);
                    }

                    if (functionCallbackConfirm) {
                        functionCallbackConfirm();
                    }
                })).catch((exception) => {
                    setSuccessDelete(false)
                    swal(i18n.t('alert_error_delete'), {
                        icon: "error",
                    })
                } );
            } else {
                if (setSuccessDelete) {
                    setSuccessDelete(null);
                }

                if (functionCallbackCancel) {
                    functionCallbackCancel();
                }
            }
        });
    }, []);

    return (<></>);
}

export default AlertDelete;