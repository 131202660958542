import styled from 'styled-components';

export const Wrapper = styled.div`
  height: auto;
  display: flex;
  flex-direction: row;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const Container = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;
