import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {IconButton} from "@material-ui/core";
import {MdDeleteForever, MdEdit} from "react-icons/md";
import AlertDelete from "../../components/Alert/AlertDelete";
import CustomButton from "../../components/Form/Button";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import {useTranslation} from "react-i18next";
import {getCurrencyPairs} from "../../services/CurrencyPair";
import currencyPairTypes from "../../constants/CurrencyPairTypes";

export default function CurrencyPair() {
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const [deleteCurrencyPair, setDeleteCurrencyPair] = useState(null);
    const { t } = useTranslation();

    useEffect( () => {
        setLoading(true);
        getCurrencyPairs().then((data) => {
            if (!data || data === '') {
                return;
            }

            setList(data.data);
            setLoading(false);
        });

        return function cleanup() {
            setList([]);
        };
    }, [deleteCurrencyPair === true]);

    return (
      <>
          <div className="row justify-content-center">
              <div className="col-lg-6 col-xl-5">
                  <div className="card shadow mb-4">
                      <div className="card-header py-3">
                          <div className="row">
                              <div className="col d-flex flex-column justify-content-center">
                                    <span className="m-0 font-weight-bold">
                                        <i className="fa fa-list" aria-hidden="true"></i> Pares de moedas
                                    </span>
                              </div>
                              <div className="col">
                                  <div className="float-right">
                                      <Link className="ml-2 btn btn-xs btn-success font-weight-bold"
                                            to="/currency-pair/new">
                                          <i className="fas fa-plus"/> {t('add')}
                                      </Link>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="card-body pt-4 p-0">
                          {loading ?
                            <div className="row pl-4 pr-4 pb-4 pt-0">
                                <div className="col">
                                    <CustomButton
                                      className="btn btn-primary col-12 text-capitalize font-weight-bold"
                                      loading={loading}
                                      type="button"
                                    >
                                        {t('loading')}
                                    </CustomButton>
                                </div>
                            </div> :
                            <>
                                <TableContainer component={Paper}>
                                    <Table aria-label="caption table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="font-weight-bold text-muted" align="center">Nome</TableCell>
                                                <TableCell className="font-weight-bold text-muted" align="center">Opções</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {list && list.data && list.data.length > 0 && list.data.map((row) => (
                                              <TableRow key={row.id}>
                                                  <TableCell align="center">
                                                      <small>
                                                          {row.name}
                                                      </small>
                                                  </TableCell>
                                                  <TableCell align="center">
                                                      <Link to={`/currency-pair/${row.id}/edit`}>
                                                          <IconButton
                                                            aria-label="edit"
                                                          >
                                                              <MdEdit/>
                                                          </IconButton>
                                                      </Link>
                                                      <IconButton
                                                        aria-label="delete"
                                                        onClick={() => {
                                                            setDeleteCurrencyPair(row.id)
                                                        }}
                                                      >
                                                          <MdDeleteForever/>
                                                          {row.id === deleteCurrencyPair && <AlertDelete
                                                            setSuccessDelete={setDeleteCurrencyPair}
                                                            urlDelete={process.env.REACT_APP_API_ENDPOINT + `/api/currency-pair/delete/${row.id}`}
                                                          />}
                                                      </IconButton>
                                                  </TableCell>
                                              </TableRow>
                                            ))}
                                            {currencyPairTypes.map((row, index) => (
                                              <TableRow key={index}>
                                                  <TableCell align="center">
                                                      <small>
                                                          {row.value}
                                                      </small>
                                                  </TableCell>
                                                  <TableCell align="center">
                                                  </TableCell>
                                              </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                          }
                      </div>
                  </div>
              </div>
          </div>
      </>
    );
}