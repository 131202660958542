import React, {useEffect, useRef, useState} from 'react';
import CustomButton from "../../../../components/Form/Button";
import {getCommission} from "../../../../services/Profile";
import {Alert, Checkbox} from "@material-ui/core";
import messageTypes from "../../../../constants/MessagesTypes";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import {useTranslation} from "react-i18next";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";
import {GiReceiveMoney} from "react-icons/gi";
import {currencyFormat} from "../../../../utils/masks";
import {Form} from "@unform/web";
import Input from "../../../../components/Form/Input";
import {AiOutlineSearch} from "react-icons/ai";
import * as Yup from "yup";
import {toast} from "react-toastify";
import InputSelect from "../../../../components/Form/InputSelect";
import {GoChecklist} from "react-icons/go";
import FormPaymentCommission from "../../../../components/Form/FormPaymentCommission";

export default function Commission() {
  const [openModalPayment, setOpenModalPayment] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const { t } = useTranslation();
  const [commissions, setCommissions] = useState([]);
  const [page, setPage] = useState(1);
  const formRef = useRef(null);
  const [filter, setFilter] = useState({
    stringSearch: '',
    status: 'PENDING',
  });

  const optionsStatus = [
    {key: 'PENDING', value: 'Pendente'},
    {key: 'PAY', value: 'Paga'},
  ];

  const [selectedCommissions, setSelectedCommissions] = useState([]);

  const handleSelectCommission = (id) => {
    setSelectedCommissions((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  async function handleSubmit(data) {
    try {

      formRef.current.setErrors({});
      const schema = Yup.object().shape({});

      await schema.validate(data, {
        abortEarly: false,
      });

      setFilter({
        stringSearch: data.stringSearch,
        status: data.status
      });

    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors = {};
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        toast.warning(messageTypes.REQUIRED_FIELDS);
        return;
      }

      toast.error(messageTypes.ERROR);
    }
  }

  useEffect( () => {
    setLoadingList(true);
    getCommission({
      page: page,
      per_page: 25,
      stringSearch: filter.stringSearch,
      status: filter.status,
    }).then((data) => {
      if (!data || data === '') {
        return;
      }

      setCommissions(data.data);
    }).finally(() => {
      setLoadingList(false);
    });

    return function cleanup() {
      setCommissions([]);
    };
  }, [page, filter]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlePaymentCommission = () => {
    setOpenModalPayment(true);
  };

    return (
      <>
        <div className="row d-flex justify-content-center">
          <div className="col-md-9 col-sm-12">
            <div className="card shadow h-100 p-3">
              <div className="card-header py-3">
                <div className="row">
                  <div className="col d-flex flex-column justify-content-center">
                            <span className="m-0 font-weight-bold">
                                <GiReceiveMoney size={18}/> Comissões
                            </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="">
                    <div className="row">
                      <div className="col p-3">
                        <Form ref={formRef} onSubmit={handleSubmit} className="pr-4">
                          <div className="row">
                            <div className={"d-flex col-12"}>
                              <div className="mt-3 w-100 col-7">
                                <Input
                                  type="text"
                                  name="stringSearch"
                                  placeholder={"ID, nome ou e-mail"}
                                  label={'Pesquisar usuário'}
                                />
                              </div>
                              <div className="col-3 col-xs-12 mt-3">
                                <InputSelect
                                  type="text"
                                  name="status"
                                  label={'Status'}
                                  options={optionsStatus}
                                  defaultValue={'PENDING'}
                                />
                              </div>
                              <CustomButton
                                className="btn btn-primary font-weight-bold text-capitalize mt-3 ml-3 col-2"
                                type="submit"
                                startIcon={<AiOutlineSearch/>}
                              >
                                {t('filter_btn_submmit')}
                              </CustomButton>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr/>
              {loadingList ?
                <div className="row pl-4 pr-4 pb-4 pt-0">
                  <div className="col">
                    <CustomButton
                      className="btn btn-primary col-12 text-capitalize font-weight-bold"
                      loading={loadingList}
                      type="button"
                    >
                      {t('loading')}
                    </CustomButton>
                  </div>
                </div> :
                <>
                  {commissions && commissions.data && commissions.data.length ?
                    <TableContainer component={Paper}>
                      <Table aria-label="caption table">
                        <TableHead>
                          <TableRow>
                            <TableCell padding="checkbox">
                              <Checkbox
                                indeterminate={
                                  selectedCommissions.length > 0 && selectedCommissions.length < commissions.data.length
                                }
                                checked={selectedCommissions.length === commissions.data.length}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSelectedCommissions(commissions.data.map((commission) => commission.id));
                                  } else {
                                    setSelectedCommissions([]);
                                  }
                                }}
                              />
                            </TableCell>
                            <TableCell className="font-weight-bold text-muted">Data</TableCell>
                            <TableCell className="font-weight-bold text-muted">Afiliado</TableCell>
                            <TableCell className="font-weight-bold text-muted">Valor</TableCell>
                            <TableCell className="font-weight-bold text-muted">Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {commissions.data.map((commission) => (
                            <TableRow key={commission.id}>
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={selectedCommissions.includes(commission.id)}
                                  onChange={() => handleSelectCommission(commission.id)}
                                />
                              </TableCell>
                              <TableCell>
                                {new Date(commission.created.date).toLocaleDateString()}
                              </TableCell>
                              <TableCell>
                                {commission.affiliateUser ? commission.affiliateUser.email : ''}
                                <br/>
                                <small>
                                  ID: {commission.affiliateUser ? (commission.affiliateUser.id).split('-')[0] : ''}
                                  <br/>
                                  ID
                                  afiliação: {commission.affiliateUser ? (commission.affiliateUser.affiliateID).split('-')[0] : ''}
                                </small>
                              </TableCell>
                              <TableCell>
                                {currencyFormat(parseFloat(commission.value ? commission.value : 0), 'R$')}
                              </TableCell>
                              <TableCell>
                                {!commission.status ? '-' : ''}
                                {commission.status === 'PENDING' ?
                                  <span className="badge badge-warning">Pendente</span> : ''}
                                {commission.status === 'PAY' ? <span className="badge badge-success">Pago</span> : ''}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter>
                          <TableCell colSpan="5">
                            <div className={"float-left"}>
                              {selectedCommissions.length > 0 &&
                                <button
                                  style={{cursor: 'pointer'}}
                                  type="button"
                                  onClick={handlePaymentCommission}
                                  className="btn btn-secondary mr-3 float-right float-left">
                                  <GoChecklist /> Registrar pagamento
                                </button>
                              }
                            </div>
                            <span className="text-muted text-info float-left pl-4 pt-2">
                                {t('table_pagination_text', {
                                  'page': page,
                                  'totalPage': commissions.total_page + 1
                                })}
                            </span>
                            <button
                              style={{cursor: !commissions.next_page ? 'not-allowed' : 'pointer'}}
                              type="button"
                              onClick={() => setPage(page + 1)}
                              disabled={!commissions.next_page}
                              className="btn btn-light mr-3 float-right">
                              {t('table_pagination_next')} <IoIosArrowForward
                            />
                            </button>
                            <button
                              style={{cursor: page <= 1 ? 'not-allowed' : 'pointer'}}
                              type="button"
                              onClick={() => setPage(page - 1)}
                              disabled={page <= 1}
                              className="btn btn-light mr-3 float-right"
                            >
                              <IoIosArrowBack/> {t('table_pagination_back')}
                            </button>
                          </TableCell>
                        </TableFooter>
                      </Table>
                    </TableContainer> :
                    <div className="row pb-4 pt-0">
                      <div className="col">
                        <Alert severity="warning">{messageTypes.REGISTER_NOT_FOUNT}</Alert>
                      </div>
                    </div>
                  }
                </>
              }
              <br/>
            </div>
          </div>
        </div>
        <FormPaymentCommission
          isOpen={openModalPayment}
          commissions={selectedCommissions}
          handleClose={() => {setOpenModalPayment(false)}}
        />
      </>
    )
}