import * as React from 'react';
import {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {GoChecklist} from "react-icons/go";
import CustomButton from "../Button";
import swal from "@sweetalert/with-react";
import * as Yup from "yup";
import {save} from "../../../services/PaymentCommission";
import {toast} from "react-toastify";
import messageTypes from "../../../constants/MessagesTypes";

export default function FormPaymentCommission({handleClose = () => {}, isOpen = false, commissions}) {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  function alertFileSize() {
    swal({
      text: 'Por favor, escolha um arquivo com um tamanho menor. O tamanho máximo permitido é de 2MB.',
    });
  }

  async function handleSubmmit(event, data) {
    event.preventDefault();
    try {

      if (loading) {
        return;
      }

      setLoading(true);
      const schema = Yup.object().shape({
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const formData = new FormData();
      formData.append('commissionsIds', commissions);

      if (file && file !== '') {
        formData.append('file', file);
      }

      const response = await save(formData);

      if (response && response.status === 200) {
        toast.success(messageTypes.SUCCESS);
      } else {
        toast.error(messageTypes.ERROR_SAVE);
      }

      setLoading(false);
      handleClose();

    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.error(messageTypes.ERROR);
    }
  }


  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          style: { width: '40%' },
          onSubmit: handleSubmmit,
        }}
      >
        <DialogTitle><GoChecklist /> Registrar pagamento</DialogTitle>
        <DialogContent>
          <div>
            <span className={"text-muted"}>Selecione o comprovante</span>
            <div className={"cursor-pointer mt-1"}>
              <input className={"text-muted"} type="file" accept="image/*,application/pdf"
                   onChange={(event) => {
                     const file = event.target.files[0];
                     const maxSizeInBytes = 2 * 1024 * 1024;
                     if (file && file.size > maxSizeInBytes) {
                       alertFileSize();
                       event.target.value = null;
                       return;
                     }
                     setFile(event.target.files[0]);
                   }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions className={"mb-2 mt-2"}>
          <CustomButton
            onClick={handleClose}
            variant="outlined"
            className="btn btn-secondary font-weight-bold text-capitalize"
          >
            Fechar
          </CustomButton>
          <CustomButton
            type={'submit'}
            loading={loading}
            className="btn btn-primary font-weight-bold text-capitalize"
          >
            Salvar
          </CustomButton>
          <br/>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
