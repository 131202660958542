import React, {useEffect, useRef, useState} from "react";
import CardTotalGain from "../../../components/Report/CardTotalGain";
import CardTotalLoss from "../../../components/Report/CardTotalLoss";
import CardProfitPrejudice from "../../../components/Report/CardProfitPrejudice";
import ReportTrading from "../../../components/Report/ReportTrading";
import DivDiagonal from "../../../components/DivDiagonal";
import {Form} from "@unform/web";
import * as Yup from "yup";
import {toast} from "react-toastify";
import messageTypes from "../../../constants/MessagesTypes";
import {AiOutlineSearch} from "react-icons/ai";
import CustomButton from "../../../components/Form/Button";
import {dateCurrent, dateFirstDayCurrent, firstDayMonth} from "../../../utils/date";
import CardTotalDraw from "../../../components/Report/CardTotalDraw";
import InputDate from "../../../components/Form/InputDate";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

export default function TradingReport() {
    const formRef = useRef(null);
    const { t } = useTranslation();
    const {isActiveSignature} = useSelector(
        (state) => state.UserReducer
    );

    const [filter, setFilter] = useState([
        {
            startDate: dateFirstDayCurrent(),
            endDate: dateCurrent(),
        }
    ]);

    async function handleSubmit(data) {
        try {

            formRef.current.setErrors({});
            const schema = Yup.object().shape({});

            await schema.validate(data, {
                abortEarly: false,
            });

            setFilter(
              [{
                  startDate: data.startDate,
                  endDate: data.endDate,
              }]
            );

        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const validationErrors = {};
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
                toast.warning(messageTypes.REQUIRED_FIELDS);
                return;
            }

            toast.error(messageTypes.ERROR);
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {isActiveSignature && <>
                <DivDiagonal/>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="h3 mb-0 text-gray-400"><i className="far fa-file-alt"/> {t('traging_report_title')}</h1>
                        </div>
                    </div>
                </div>
                <div className="card shadow mb-4 h-100">
                    <div className="card-body">
                        <div className="bg-gray-100">
                            <div className="row">
                                <div className="col">
                                    <Form ref={formRef} onSubmit={handleSubmit} className="p-3">
                                        <div className="row">
                                            <div className="col-md-12 pull-left d-flex align-items-center ml-1">
                                                <div className="font-weight-bold mb-1"><nobr><i className="fas fa-funnel-dollar"></i> {t('filter')}</nobr></div>
                                            </div>
                                            <div className="col-md-4 col-xs-12 mt-3">
                                                <InputDate
                                                    name="startDate"
                                                    label={t('filter_start_date')}
                                                    value={firstDayMonth()}
                                                />
                                            </div>
                                            <div className="col-md-4 col-xs-12 mt-3">
                                                <InputDate
                                                    name="endDate"
                                                    label={t('filter_end_date')}
                                                    value={new Date()}
                                                />
                                            </div>
                                            <CustomButton
                                                className="btn btn-primary float-right font-weight-bold text-capitalize mt-3 ml-3 mr-3"
                                                type="submit"
                                                startIcon={<AiOutlineSearch/>}
                                            >
                                                {t('filter_btn_submmit')}
                                            </CustomButton>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="row mt-4">
                            <div className="col-xl-4 mb-4 ">
                                <CardTotalGain
                                    className="card text-gray-800 h-100 py-2"
                                    filter={filter}
                                />
                            </div>
                            <div className="col-xl-4 mb-4 ">
                                <CardTotalLoss
                                    className="card text-gray-800 h-100 py-2"
                                    filter={filter}
                                />
                            </div>
                            <div className="col-xl-4 mb-4 ">
                                <CardProfitPrejudice
                                    filter={filter}
                                />
                            </div>
                        </div>
                        <ReportTrading filter={filter}/>
                    </div>
                </div>
            </>}
        </>
    );
}