import api from "../api";
import {toast} from "react-toastify";
import messageTypes from "../../constants/MessagesTypes";

export async function load() {
    return api.get(`/api/user/profile`, {
        headers: {
            'Content-Type': 'multipart/form-data',
            auth: true
        },
    }).catch((error) => {
        toast.error(messageTypes.ERROR_LOAD);
        return [];
    });
}

export async function save($user) {
    return api.post(`/api/user/edit`, $user, {
        headers: {
            'Content-Type': 'application/json',
            auth: true
        },
    }).catch((error) => {
        toast.error(messageTypes.ERROR_LOAD);
        return [];
    });
}

export async function setShowTour(isTour) {
    api.post('/api/user/show-tour/'+isTour, null,{
        headers: {
            'Content-Type': 'multipart/form-data',
            auth: true
        },
    }).catch((error) => {
        toast.error(messageTypes.ERROR);
    });
}

export async function getShowTour() {
    return api.get(`/api/user/get-show-tour`, {
        headers: {
            'Content-Type': 'multipart/form-data',
            auth: true
        },
    }).catch((error) => {
        toast.error(messageTypes.ERROR_LOAD);
        return [];
    });
}

export async function setShowTourImport(isTourImport) {
    api.post('/api/user/show-tour-import/'+isTourImport, null,{
        headers: {
            'Content-Type': 'multipart/form-data',
            auth: true
        },
    }).catch((error) => {
        toast.error(messageTypes.ERROR);
    });
}

export async function getShowTourImport() {
    return api.get(`/api/user/get-show-tour-import`, {
        headers: {
            'Content-Type': 'multipart/form-data',
            auth: true
        },
    }).catch((error) => {
        toast.error(messageTypes.ERROR_LOAD);
        return [];
    });
}

export async function setShowTourTrading(isTourTrading) {
    api.post('/api/user/show-tour-trading/'+isTourTrading, null,{
        headers: {
            'Content-Type': 'multipart/form-data',
            auth: true
        },
    }).catch((error) => {
        toast.error(messageTypes.ERROR);
    });
}

export async function getShowTourTrading() {
    return api.get(`/api/user/get-show-tour-trading`, {
        headers: {
            'Content-Type': 'multipart/form-data',
            auth: true
        },
    }).catch((error) => {
        toast.error(messageTypes.ERROR_LOAD);
        return [];
    });
}

export async function newSignature($data) {
    return await api.post('/api/signature/new', $data, {
        headers: {
            'Content-Type': 'application/json',
            auth: true
        },
    });
}

export async function testSignature() {
    return await api.post('/api/signature/test', null, {
        headers: {
            'Content-Type': 'application/json',
            auth: true
        },
    });
}

export async function getSignature() {
    return api.get(`/api/signature/view`, {
        headers: {
            'Content-Type': 'multipart/form-data',
            auth: true
        },
    }).catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
            return;
        }
        toast.error(messageTypes.ERROR_LOAD);
    });
}

export async function setShowModalInfo(isSHow) {
    api.post('/api/user/show-modal-info/'+isSHow, null,{
        headers: {
            'Content-Type': 'multipart/form-data',
            auth: true
        },
    }).catch((error) => {
        toast.error(messageTypes.ERROR);
    });
}

export async function getShowModalInfo() {
    return api.get(`/api/user/get-show-modal-info`, {
        headers: {
            'Content-Type': 'multipart/form-data',
            auth: true
        },
    }).catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
            throw error;
        }
        toast.error(messageTypes.ERROR_LOAD);
        throw error;
    });
}

export async function newAffiliate() {
    return api.put(`/api/user/new-affiliate`, null, {
        headers: {
            'Content-Type': 'application/json',
            auth: true
        },
    }).catch((error) => {
        toast.error(messageTypes.ERROR_LOAD);
        return [];
    });
}

export async function saveKeyPix(data) {
    return api.post(`/api/user/save-key-pix`, data, {
        headers: {
            'Content-Type': 'application/json',
            auth: true
        },
    }).catch((error) => {
        toast.error(messageTypes.ERROR_LOAD);
        return [];
    });
}

export async function getReferredUsers(data) {
    return api.get(`/api/user/list-referred-users`, {
        params: data,
        headers: {
            'Content-Type': 'multipart/form-data',
            auth: true
        },
    }).catch((error) => {
        toast.error(messageTypes.ERROR_LOAD);
        return[];
    });
}

export async function getBalanceAffiliateProgram() {
    return api.get(`/api/user/get-balance-affiliate-program`, {
        params: {},
        headers: {
            'Content-Type': 'multipart/form-data',
            auth: true
        },
    }).catch((error) => {
        toast.error(messageTypes.ERROR_LOAD);
        return[];
    });
}

export async function getAffiliates(data ) {
    return api.get(`/api/admin/affiliate-program/affiliates`, {
        params: data,
        headers: {
            'Content-Type': 'multipart/form-data',
            auth: true
        },
    }).catch((error) => {
        toast.error(messageTypes.ERROR_LOAD);
        return[];
    });
}

export async function getCommission(data ) {
    return api.get(`/api/admin/affiliate-program/commissions`, {
        params: data,
        headers: {
            'Content-Type': 'multipart/form-data',
            auth: true
        },
    }).catch((error) => {
        toast.error(messageTypes.ERROR_LOAD);
        return[];
    });
}

export async function getCommissionsPayments(data ) {
    return api.get(`/api/admin/affiliate-program/commission/payment/list`, {
        params: data,
        headers: {
            'Content-Type': 'multipart/form-data',
            auth: true
        },
    }).catch((error) => {
        toast.error(messageTypes.ERROR_LOAD);
        return[];
    });
}