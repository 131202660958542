import React, {useCallback, useEffect, useRef, useState} from 'react';
import DivDiagonal from "../../components/DivDiagonal";
import CustomButton from "../../components/Form/Button";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getBalanceAffiliateProgram, getReferredUsers, newAffiliate, saveKeyPix} from "../../services/Profile";
import {actions} from "../../redux/actions";
import {toast} from "react-toastify";
import {Alert, Button, TextField} from "@material-ui/core";
import {FaRegCopy} from "react-icons/fa";
import * as Yup from "yup";
import messageTypes from "../../constants/MessagesTypes";
import {Form} from "@unform/web";
import {IoIosArrowBack, IoIosArrowForward, IoMdCheckmark} from "react-icons/io";
import {useTranslation} from "react-i18next";
import Input from "../../components/Form/Input";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";
import {currencyFormat} from "../../utils/masks";
import {BsWhatsapp} from "react-icons/bs";
import swal from "@sweetalert/with-react";

export default function AffiliateProgram() {
  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const [linkAfilliate, setLinkAfilliate] = useState(null)
  const { t } = useTranslation();
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [balanceAffiliateProgram, setBalanceAffiliateProgram] = useState();
  const [valueMinPayment, setValueMinPayment] = useState(20);
  const {affiliateID, keyPix, isFreeSignature, isActiveSignature} = useSelector(
    (state) => state.UserReducer
  );

  const [keyPixState, setKeyPixState] = useState(null);

  const updateKeyPix = useCallback(() => {
   setKeyPixState(keyPix)
  }, [keyPix]);

  useEffect(updateKeyPix, [keyPix]);

  const generateLinkAffiliate = useCallback(() => {
    const baseUrl = process.env.NODE_ENV === 'development'
      ? 'http://localhost:3000'
      : 'https://gbinary.space'
    ;

    setLinkAfilliate(`${baseUrl}/register?aff=${affiliateID}`);
  }, [affiliateID]);

  useEffect(generateLinkAffiliate, [affiliateID]);

  const handleCopy = () => {
    navigator.clipboard.writeText(linkAfilliate).then(() => {
      toast.success('Link de afiliado copiado com sucesso!');
    }).catch(err => {
      toast.success('Erro ao copiar link');
      console.log(err)
    });
  };

  function handleConfirmAffiliate() {
    if (isFreeSignature || !isActiveSignature) {
      swal(
        {
          icon: "info",
          text: t('alert_signature'),
          buttons: [t('alert_signature_btn_close'), t('alert_signature_confirm')],
        }
      ).then((isConfirm) => {
        if (isConfirm) {
          history.push('/plans');
        }
      });

      return;
    }

    if (loading) {
      return;
    }

    setLoading(true);
    newAffiliate().then((data) => {
      dispatch(actions.setAffiliateID(data.data.affiliateID));
    }).finally(() => {
      setLoading(false);
    });
  }

  async function handleSaveKeyPix(data) {
    try {
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        keyPix: Yup.string().required(t('input_required')),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const formData = new FormData();
      formData.append('keyPix', data.keyPix);
      const response = await saveKeyPix(formData);

      if (response && response.status === 200) {
        toast.success(messageTypes.SUCCESS);
      } else {
        toast.error(messageTypes.ERROR);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors = {};
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        toast.warning(messageTypes.REQUIRED_FIELDS);
        return;
      }

      toast.error(messageTypes.ERROR);
    }
  }

  useEffect( () => {
    setLoadingList(true);
    getReferredUsers({
      page: page,
      per_page: 25,
    }).then((data) => {
      if (!data || data === '') {
        return;
      }

      setList(data.data);
    }).finally(() => {
      setLoadingList(false);
    });

    getBalanceAffiliateProgram().then((data) => {
      setBalanceAffiliateProgram(data.data.balanceAffiliateProgram? data.data.balanceAffiliateProgram : 0);
    });

    return function cleanup() {
      setList([]);
    };
  }, [page]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    return (
        <>
            <DivDiagonal/>
          {!affiliateID ? <>
            <div className="mb-4">
              <div className="row">
                <div className="col-12 text-white mt-4">
                  <h1 className="h3 mb-0 text-center"><i className="fa fa-users" aria-hidden="true"></i> Seja um
                    Afiliado da Nossa Plataforma!</h1>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-md-8 col-sm-12">
                <div className="card shadow h-100 p-5">
                  <p>
                    Ao se tornar um afiliado, você terá a oportunidade de compartilhar nossa plataforma com amigos e
                    conhecidos. E o melhor: para cada novo usuário que se inscrever usando o seu link exclusivo e ativar
                    uma assinatura, você receberá entre 20% e 30% do valor da assinatura como bonificação.
                  </p>
                  <p>Deseja se tornar um afiliado agora?</p>
                  <CustomButton
                    color="success"
                    className="font-weight-bold"
                    loading={loading}
                    onClick={() => {
                      handleConfirmAffiliate();
                    }}
                  >
                    <span className={"text-capitalize"}>Sim, <span
                      className={"text-lowercase"}>quero ser afiliado</span>!</span>
                  </CustomButton>
                  <br/>
                  <CustomButton
                    color="error"
                    className="font-weight-bold"
                    variant="outlined"
                    onClick={() => {
                      history.push('/dashboard');
                    }}
                  >
                    <span className={"text-capitalize"}>não, <span className={"text-lowercase"}>obrigado</span>!</span>
                  </CustomButton>
                </div>
              </div>
            </div>
          </> : <>
            <div className="mb-4">
              <div className="row">
                <div className="col-12 text-white mt-4">
                  <h1 className="h3 mb-0 text-center"><i className="fa fa-users" aria-hidden="true"></i> Programa de
                    afiliados</h1>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-md-10 col-sm-12">
                <div className="card shadow h-100 p-3">
                  {isActiveSignature === false &&
                    <div className="row pb-4 pt-0">
                      <div className="col d-flex align-items-center">
                        <Alert severity="warning" className={"d-flex align-items-center"}>
                          <strong>
                            Atenção! Sua assinatura está inativa. Dessa forma, você não poderá receber novos usuários
                            indicados pelo seu link de afiliado. Para voltar a aproveitar todos os benefícios, ative sua
                            assinatura!
                          </strong>
                        </Alert>
                      </div>
                    </div>
                  }
                  <div className="row d-flex justify-content-center align-items-center mb-5 mt-4">
                    <div className="col-md-12 text-center col-lg-6 pt-3">
                      <div className="col-12 card-balance pt-4 pb-2">
                        <h6 className="lead font-weight-bold pricing-plan-title">Seu saldo</h6>
                        <p className="font-weight-bold pricing-plan-amount">
                          <nobr>R$ <span
                            className="h1 font-weight-bolder">{currencyFormat(parseFloat(balanceAffiliateProgram))}</span>
                          </nobr>
                        </p>
                        <Button
                          variant="contained"
                          className="btn text-capitalize btn-outline-primary font-weight-bold cursor-pointer"
                          type="button"
                          onClick={() => {
                            const url = `https://web.whatsapp.com/send?phone=38999361310&text=Olá, gostaria de solicitar o pagamento do meu saldo disponível referente ao programa de afiliado`;
                            window.open(url, '_blank');
                          }}
                          disabled={balanceAffiliateProgram < valueMinPayment}
                          startIcon={<BsWhatsapp color={'#25D366'}/>}
                        >
                          Solicitar pagamento
                        </Button>
                        <p className="small mt-3 text-left">
                          Atenção! Para solicitar o pagamento, é necessário que seu saldo seja superior
                          a {currencyFormat(parseFloat(valueMinPayment), 'R$')}.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className={'col-12 d-flex align-items-center'}>
                      <TextField
                        label="Link de afiliado"
                        value={linkAfilliate}
                        InputProps={{
                          readOnly: true,
                        }}
                        size="small"
                        variant="outlined"
                        fullWidth
                      />
                      <CustomButton
                        className="font-weight-bold"
                        loading={loading}
                        onClick={handleCopy}
                        startIcon={<FaRegCopy/>}
                        variant={'outlined'}
                      >
                        <span className={"text-capitalize"}>Copiar</span>
                      </CustomButton>
                    </div>
                  </div>
                  <Form ref={formRef} onSubmit={handleSaveKeyPix}>
                    <div className="row mt-4">
                      <div className={'col-12 d-flex align-items-center'}>
                        <Input
                          type={'text'}
                          name={'keyPix'}
                          label="Informe sua chave pix aleatória"
                          defaultValue={keyPixState ? keyPixState : ''}
                          value={keyPixState ? keyPixState : ''}
                          onChange={(e) => {
                            setKeyPixState(e.target.value);
                          }}
                          size="small"
                          placeholder={"Informe sua chave pix aleatória"}
                          fullWidth
                        />
                        <CustomButton
                          className="font-weight-bold"
                          startIcon={<IoMdCheckmark/>}
                          type="submit"
                        >
                          <span className={"text-capitalize"}>Salvar</span>
                        </CustomButton>
                      </div>
                    </div>
                  </Form>
                  <br/>
                  <br/>
                  {loadingList ?
                    <div className="row pl-4 pr-4 pb-4 pt-0">
                      <div className="col">
                        <CustomButton
                          className="btn btn-primary col-12 text-capitalize font-weight-bold"
                          loading={loadingList}
                          type="button"
                        >
                          {t('loading')}
                        </CustomButton>
                      </div>
                    </div> :
                    <>
                      <div className={"mb-3"}>Usuários cadastrados com seu link de afiliado</div>
                      {list && list.data && list.data.length ?
                        <TableContainer component={Paper}>
                          <Table aria-label="caption table">
                            <TableHead>
                              <TableRow>
                                <TableCell className="font-weight-bold text-muted" align="center">ID do
                                  usuário</TableCell>
                                <TableCell className="font-weight-bold text-muted" align="center">Data do
                                  registro</TableCell>
                                <TableCell className="font-weight-bold text-muted" align="center">Comissão</TableCell>
                                <TableCell className="font-weight-bold text-muted" align="center">Status do
                                  pagamento</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {list.data.map((row) => (
                                <TableRow key={row.id}>
                                  <TableCell align="center">
                                    {(row.id).split('-')[0]}
                                  </TableCell>
                                  <TableCell align="center">
                                    {new Date(row.created).toLocaleDateString()}
                                  </TableCell>
                                  <TableCell align="center">
                                    {currencyFormat(parseFloat(row.valueComission ? row.valueComission : 0), 'R$')}
                                  </TableCell>
                                  <TableCell align="center">
                                    {!row.statusComission ? '-' : ''}
                                    {row.statusComission === 'PENDING' ?
                                      <span className="badge badge-warning">Pendente</span> : ''}
                                    {row.statusComission === 'PAY' ?
                                      <span className="badge badge-success">Pago</span> : ''}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                            <TableFooter>
                              <TableCell colSpan="5">
                                <span className="text-muted text-info float-left pl-4 pt-2">
                                    {t('table_pagination_text', {
                                      'page': page,
                                      'totalPage': list.total_page + 1
                                    })}
                                </span>
                                <button
                                  style={{cursor: !list.next_page ? 'not-allowed' : 'pointer'}}
                                  type="button"
                                  onClick={() => setPage(page + 1)}
                                  disabled={!list.next_page}
                                  className="btn btn-light mr-3 float-right">
                                  {t('table_pagination_next')} <IoIosArrowForward
                                />
                                </button>
                                <button
                                  style={{cursor: page <= 1 ? 'not-allowed' : 'pointer'}}
                                  type="button"
                                  onClick={() => setPage(page - 1)}
                                  disabled={page <= 1}
                                  className="btn btn-light mr-3 float-right"
                                >
                                  <IoIosArrowBack/> {t('table_pagination_back')}
                                </button>
                              </TableCell>
                            </TableFooter>
                          </Table>
                        </TableContainer> :
                        <div className="row pb-4 pt-0">
                          <div className="col">
                            <Alert severity="warning">{messageTypes.REGISTER_NOT_FOUNT}</Alert>
                          </div>
                        </div>
                      }
                    </>
                  }
                  <br/>
                </div>
              </div>
            </div>
          </>}
        </>
    )
}