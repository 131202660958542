import React, {useEffect, useRef, useState} from 'react';
import DivDiagonal from "../../components/DivDiagonal";
import CardRankingWin from "../../components/Report/CardRankingWin";
import CardRankingLoss from "../../components/Report/CardRankingLoss";
import {dateCurrent, dateFirstDayCurrent, firstDayMonth} from "../../utils/date";
import * as Yup from "yup";
import {toast} from "react-toastify";
import messageTypes from "../../constants/MessagesTypes";
import {Form} from "@unform/web";
import CustomButton from "../../components/Form/Button";
import {AiOutlineSearch} from "react-icons/ai";
import GeneralDoughnut from "../../components/Charts/GeneralDoughnut";
import TradingAgroupMonth from "../../components/Charts/TradingAgroupMonth";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import InputDate from "../../components/Form/InputDate";
import StrategyReport from "../../components/Report/StrategyReport";
import {useTranslation} from "react-i18next";
import InputSelect from "../../components/Form/InputSelect";

export default function Dashboard() {
    const formRef = useRef(null);
    const [year,] = useState(new Date().getFullYear());
    const history = useHistory();
    const { t } = useTranslation();
    const optionsDirection = [
        {key: 'COMPRA', value: t('trading_label_input_direction_select_option_1')},
        {key: 'VENDA', value: t('trading_label_input_direction_select_option_2')},
    ];

    const { id } = useSelector(
        (state) => state.BankReducer
    );

    const [filter, setFilter] = useState([
        {
            startDate: dateFirstDayCurrent(),
            endDate: dateCurrent(),
        }
    ]);

    useEffect(() => {
        if (id === 0) {
            history.push('/bank');
        }
        document.getElementById('body').className = 'page-top'
    }, [id]);

    async function handleSubmit(data) {
        try {

            formRef.current.setErrors({});
            const schema = Yup.object().shape({});

            await schema.validate(data, {
                abortEarly: false,
            });

            setFilter(
                [{
                    startDate: data.startDate,
                    endDate: data.endDate,
                    direction: data.direction
                }]
            );

        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const validationErrors = {};
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
                toast.warning(messageTypes.REQUIRED_FIELDS);
                return;
            }

            toast.error(messageTypes.ERROR);
        }
    }

    return (
        <>
            {(id !== 0 && id !== null) && <>
                <DivDiagonal/>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <div className="row">
                        <div className="col-12 text-white">
                            <h1 className="h3 mb-0"><i className="fas fa-fw fa-tachometer-alt"/> Dashboard</h1>
                        </div>
                    </div>
                </div>
                <div className="card shadow mb-4 h-100">
                    <div className="card-body">
                        <Form ref={formRef} onSubmit={handleSubmit} className="pb-2">
                            <div className="row">
                                <div className="col-md-12 pull-left d-flex align-items-center ml-1">
                                    <div className="font-weight-bold mb-1"><nobr><i className="fas fa-funnel-dollar"/> {t('filter')}</nobr></div>
                                </div>
                                <div className="col-md-3 col-xs-12 mt-3">
                                    <InputDate
                                        name="startDate"
                                        label={t('filter_start_date')}
                                        value={firstDayMonth()}
                                    />
                                </div>
                                <div className="col-md-3 col-xs-12 mt-3">
                                    <InputDate
                                        name="endDate"
                                        label={t('filter_end_date')}
                                        value={new Date()}
                                    />
                                </div>
                                <div className="col-md-3 col-xs-12 mt-3">
                                    <InputSelect
                                        type="text"
                                        name="direction"
                                        label={t('trading_label_input_direction')}
                                        options={optionsDirection}
                                        defaultValue={''}
                                    />
                                </div>
                                <CustomButton
                                    className="btn btn-primary float-right font-weight-bold text-capitalize mt-3 ml-3 mr-2"
                                    type="submit"
                                    startIcon={<AiOutlineSearch/>}
                                >
                                    {t('filter_btn_submmit')}
                                </CustomButton>
                            </div>
                        </Form>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col">
                        <div className="row">
                            <CardRankingWin
                                className="col-xl-4 mb-4"
                                filter={filter}
                            />
                            <CardRankingLoss
                                className="col-xl-4 mb-4"
                                filter={filter}
                            />
                            <div className="col-xl-4 mb-4">
                                <div className="card shadow mb-4 h-100">
                                    <div className="card-header py-3">
                                        <div className="row">
                                            <div className="col d-flex flex-column justify-content-center">
                                            <span className="m-0 font-weight-bold">
                                                <nobr>
                                                    <i className="fas fa-info-circle fa-1x"/> {t('report_general_doughnut_title')}
                                                </nobr>
                                            </span>
                                            </div>
                                            <div className="col">
                                                <div className="float-right">
                                                    <i className="fas fa-chart-pie fa-1x"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body pb-0 mb-0">
                                        <GeneralDoughnut
                                            filter={filter}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-8 mb-4">
                                <div className="card shadow mb-4 h-100">
                                    <div className="card-header py-3">
                                        <div className="row">
                                            <div className="col d-flex flex-column justify-content-center">
                                                <span className="m-0 font-weight-bold">
                                                    <i className="fa fa-list"/> {t('report_strategies_title')}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body pb-0 mb-0">
                                        <StrategyReport
                                            filter={filter}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-4 mb-4">
                                <div className="card shadow mb-4 h-100">
                                    <div className="card-header py-3">
                                        <div className="row">
                                            <div className="col d-flex flex-column justify-content-center">
                                    <span className="m-0 font-weight-bold">
                                            <nobr><i className="far fa-calendar-alt"/> {t('report_month_title')} - {year}</nobr>
                                    </span>
                                            </div>
                                            <div className="col">
                                                <div className="float-right">
                                                    <i className="fas fa-chart-bar fa-1x"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body pb-0 mb-0">
                                        <TradingAgroupMonth
                                            year={year}
                                            filter={filter}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>}
        </>
    )
}